import io from 'socket.io-client';

export const BASE_URL = 'https://gtasks-api.kuarasoftware.es';

let socket = io(BASE_URL);

export const socketID = (_id) =>
  io(BASE_URL, {
    auth: {
      _id: _id,
    },
  });

export default socket;
